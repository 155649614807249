import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const AgentSearchForm = Loadable({
    loader: () => import('./agentSearch/AgentSearchForm'),
    loading: () => null,
});

const Broker = Loadable({
    loader: () => import('./brokerLists/Index'),
    loading: () => null,
});

const HearderSearchPartner = Loadable({
    loader: () => import('./components/layout/Header'),
    loading: () => null,
});

const FooterSearchPartner = Loadable({
    loader: () => import('./components/layout/Footer'),
    loading: () => null,
});

function SearchPartner(props) {
    return (
        <>
            <HearderSearchPartner />
            <div className="site-body content-wrapper" id="page">
                <Switch>
                    <Route
                        exact
                        path={`/search-partner/agent`}
                        component={AgentSearchForm}
                    />
                    <Route path={`/search-partner/broker`} component={Broker} />
                </Switch>
            </div>
            <FooterSearchPartner />
        </>
    );
}

export default SearchPartner;
