import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const DataTable = Loadable({
    loader: () => import('./dataTableComponent'),
    loading: () => null,
});

export default function IndexLevelTableRoute() {
    return (
        <Switch>
            <Route exact path={`/index-level-table`} component={DataTable} />
        </Switch>
    );
}
