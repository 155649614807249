import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState, maintenenceGlobalState } from '../../state';
import liff from '@line/liff';
import { trackUser, trackPageView, trackEvent } from '../../utils/analytics';
import { config } from '../../config';
import { getAccountMapping } from '../../actions';
import { getParameterByName } from '../../utils/url';

const liffId = config.liff_id.suggestProduct;

function SuggestProduct(props) {
    const params = new URLSearchParams(props.location.search);
    const userId = params.get('id');
    const [user, setUser] = useRecoilState(userState);
    const liffState = decodeURIComponent(params.get('liff.state'));
    const source = liffState ? getParameterByName(liffState, 'source') : null;
    const [profile, setProfile] = useState(userId ? { userId } : {});
    const [maintenenceGlobal] = useRecoilState(maintenenceGlobalState);
    useEffect(() => {
        const initialize = async () => {
            if (userId) {
                _getAccount(userId);
            } else {
                if (liff.isInClient()) {
                    try {
                        await liff.init({ liffId });
                        const profile = await liff.getProfile();
                        setProfile(profile);
                        await _getAccount(profile.userId);
                    } catch (error) {
                        if (profile?.userId) {
                            await _getAccount(profile.userId);
                        }
                    }
                } else {
                    await _getAccount(profile.userId);
                }
            }
            window.location.href = 'https://www.prudential.co.th/corp/prudential-th/th/our-products';
            return null;
        };

        if (!maintenenceGlobal?.isMaintenance) {
            initialize();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getAccount = async _userId => {
        if (!user?.username) {
            const resultUser = await getAccountMapping(_userId);
            if (resultUser?.data && resultUser?.status === 'OK') {
                trackUser(resultUser.data);
                setUser(resultUser.data);
            }
        }

        trackPageView(source);
        trackEvent('Click Website product', 'Click Website product');
    };

    return <div></div>;
}

export default SuggestProduct;
