import React, { useState, useEffect, useMemo } from 'react';
import { Spinner, Card, CardBody, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import liff from '@line/liff';

import { trackEvent, trackPageView } from '../../utils/analytics';
import PopUp from '../../components/popup';

import { config } from '../../config';

import { getUserConsents } from '../../actions';

import { consentState, userState, } from '../../state';

function Consent(props) {
    const { consentEditable } = config
    const { i18n, t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [user] = useRecoilState(userState);
    const [, setUpdateConsent] = useRecoilState(consentState);
    const [popupProps, setPopupProps] = useState({
        type: 'error',
        isOpen: false,
        text: t('consent_setting.error_message'),
        btnText: t('close')
    })
    const [consents, setConsents] = useState([])
    const [selectOptions, setSelectOptions] = useState([])

    useEffect(async () => {
        trackPageView();
        if (!user.token) {
            return props.history.push(`/setting`);
        } else {
            _getUserConsent(user.token)
        }
    }, []);

    const _getUserConsent = async (userId) => {
        const result = await getUserConsents(userId);
        if (result.status !== "OK") {
            setPopupProps({ ...popupProps, isOpen: true });
        }
        const allowableConsents = consentEditable.map(consentName => result.data.find(consent => consent.name === consentName))
        setConsents(allowableConsents)
        setSelectOptions(allowableConsents.map(c => c.isAccepted))
        setLoading(false);
    }

    if (loading) {
        return (
            <>
                <div
                    className="text-center"
                    style={{ marginTop: 110, marginBottom: 100 }}
                >
                    <Spinner color="primary" />
                </div>
            </>
        );
    }

    const handleChooseOption = (index, isAccepted) => {
        selectOptions[index] = isAccepted;
        setSelectOptions([...selectOptions])
    }

    const handleConfirm = () => {
        const updateConsents = {
            consent: {},
            name: `${user.first_name} ${user.last_name}`
        }
        consents.forEach((c, i) => {
            updateConsents.consent[c.name] = {
                id: c.id,
                acceptance: selectOptions[i],
                isSendDataSubjectRight: c.isAccepted === true && selectOptions[i] === false
            }
        })
        trackEvent('Consent page', 'User click confirm button', { ...updateConsents });
        setUpdateConsent(updateConsents)
        props.history.push('/setting/consent-otp')
    }

    return (
        <div className="setting-consent">
            <Card>
                <CardBody>
                    <Form onSubmit={() => { }}>
                        <h4 className='center'>{t('consent_setting.title')}</h4>
                        <p className='subtitle'>{t('consent_setting.subtitle')}</p>
                        <ol>
                            {
                                consents.map((consent, i) => (
                                    <li className="consent" key={consent.id}>
                                        <div dangerouslySetInnerHTML={{ __html: consent.shortContent[i18n.language] }} />
                                        <FormGroup onClick={() => handleChooseOption(i, true)} className='option'>
                                            <Input
                                                className='radio-option'
                                                name={`consent_${consent.name}`}
                                                type="radio"
                                                checked={selectOptions[i] || false}
                                                onChange={(value) => { console.log(value) }}
                                            />
                                            {' '}
                                            <Label className='text-option'>
                                                {t('consent_setting.options.Y')}
                                            </Label>
                                        </FormGroup>
                                        <FormGroup onClick={() => handleChooseOption(i, false)} className='option'>
                                            <Input
                                                className='radio-option'
                                                name={`consent_${consent.name}`}
                                                type="radio"
                                                checked={!selectOptions[i] || false}
                                                onChange={(value) => { console.log(value) }}
                                            />
                                            {' '}
                                            <Label className='text-option'>
                                                {t('consent_setting.options.N')}
                                            </Label>
                                        </FormGroup>
                                    </li>
                                ))
                            }
                        </ol>
                        <hr />
                        <div
                            className="text-center"
                            style={{ margin: '20px 0', display: 'flex', justifyContent: 'space-around' }}
                        >
                            <Button
                                color="secondary"
                                className="btn-rounded"
                                style={{ width: '40%' }}
                                onClick={e => {
                                    e.preventDefault();
                                    trackEvent('Consent page', 'User click cancel button');
                                    props.history.push('/setting')
                                }}
                            >
                                <span>{t('consent_setting.cancel')}</span>
                            </Button>
                            <Button
                                color="primary"
                                className="btn-rounded"
                                style={{ width: '40%' }}
                                onClick={e => {
                                    e.preventDefault();
                                    handleConfirm()
                                }}
                            >
                                <span>{t('consent_setting.confirm')}</span>
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>

            <PopUp {...popupProps} onClick={() => { liff.closeWindow() }} />
        </div >
    )
}

export default Consent;
