import React from 'react';

const Loading = () => {
    return (
      <>
        <div className="text-center" style={{ margin: '40px 0' }}>
          <div className="loading"></div>
        </div>
      </>
    );
};

export default Loading