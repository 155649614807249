import { atom } from 'recoil';

export const taxConsentState = atom({
    key: 'taxConsentState',
    default: {
        insuredData: null,
        credentialData: null,
        source: 'P'
    },
});
