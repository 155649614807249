import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import reducer from '../reducers';

const middleware = [
    thunk,
    process.env.NODE_ENV !== 'production' && logger,
].filter(Boolean);

const store = createStore(reducer, applyMiddleware(...middleware));

export default store;
