import React, { useEffect } from 'react';
import liff from '@line/liff';

import { config } from '../config';

import { trackUser, trackPageView, trackEvent } from '../utils/analytics';

import { getAccountMapping } from '../actions';
import { maintenenceGlobalState } from '../state';
import { useRecoilState } from 'recoil';

// Running by Cypress, then use Stubbed LIFF SDK
if (window.Cypress) {
    window.liff = window.Cypress.liffMock;
} else {
    window.liff = liff;
}
function ContactUs(props) {
    const [maintenenceGlobal] = useRecoilState(
        maintenenceGlobalState,
    );
    useEffect(() => {
        const initialize = async () => {
            if (liff.isInClient()) {
                try {
                    await window.liff.init({
                        liffId: config.liff_id.contact,
                    });
                    const { userId } = await window.liff.getProfile();
                    const resultUser = await getAccountMapping(userId);
                    if (resultUser?.data && resultUser?.status === 'OK') {
                        trackUser(resultUser.data);
                    }
                    trackPageView();
                } catch (error) {
                    props.history.push(`/not-found`);
                }
            } else {
                trackPageView();
            }
        };

        if (!maintenenceGlobal?.isMaintenance) {
            initialize();
        }
    }, []);

    return (
        <div className="contact-page">
            <h1 className="text-center">ช่องทางการติดต่อ</h1>

            <a
                onClick={() => {
                    trackEvent('Contact Us', 'Click Website contact us');

                    setTimeout(() => {
                        window.location.href =
                            'https://www.prudential.co.th/corp/prudential-th/th/contact-us/';
                    }, 500);
                }}
                className="hotline"
            >
                <img src="assets/icons/contact/email-white.svg" alt="hotline" />
                <span>hotline@prudential.co.th</span>
            </a>

            <div className="info">
                <div className="info-item">
                    <img
                        src="assets/icons/contact/complaint-red.svg"
                        alt="tel"
                    />
                    <div>
                        <b>หน่วยงานรับเรื่องร้องเรียน</b>
                        <br />
                        <a
                            onClick={() => {
                                trackEvent('Contact Us', 'Click Complaint No.');

                                setTimeout(() => {
                                    window.location.href = 'tel:023534700';
                                }, 500);
                            }}
                        >
                            <span>
                                <b>02353 4700</b>
                            </span>
                        </a>{' '}
                        <small>(ในเวลาทำการ)</small>
                    </div>
                </div>
            </div>

            <div className="info">
                <div className="info-item">
                    <img src="assets/icons/contact/pin-red.svg" alt="pin" />
                    <div>
                        944 มิตรทาวน์ ออฟฟิศ ทาวเวอร์ ชั้น 10, 29-31 ถนนพระราม 4
                        แขวงวังใหม่ เขตปทุมวัน กรุงเทพฯ 10330
                        <br />
                        <a
                            onClick={() => {
                                trackEvent('Contact Us', 'Click Maps');

                                setTimeout(() => {
                                    window.location.href =
                                        'https://goo.gl/maps/StDH9gWWisf5pGmo6';
                                }, 500);
                            }}
                        >
                            <img
                                src="assets/icons/contact/view-map.png"
                                alt="map"
                                style={{
                                    width: 100,
                                    margin: 0,
                                    marginTop: 5,
                                }}
                            />
                        </a>
                    </div>
                </div>
                <div className="info-item">
                    <img src="assets/icons/contact/time-red.svg" alt="time" />
                    <div>
                        <b>เวลาทำการ:</b>
                        <br />
                        <p>
                            วันจันทร์ - วันศุกร์ เวลา 8.30-17.00 น.{' '}
                            <small>(ยกเว้น วันหยุดประจำปี)</small>
                        </p>
                    </div>
                </div>
            </div>

            <div className="info">
                <div className="info-item">
                    <img
                        src="assets/icons/contact/telephone-red.svg"
                        alt="fax"
                    />
                    <div>โทรสาร 0 2353 4888</div>
                </div>
            </div>

            <div className="info">
                <a
                    onClick={() => {
                        trackEvent('Contact Us', 'Click Website How to claim');

                        setTimeout(() => {
                            window.location.href =
                                'https://www.prudential.co.th/corp/prudential-th/th/how-to-claim/';
                        }, 500);
                    }}
                    style={{ color: '#707070' }}
                >
                    <div className="info-item">
                        <img
                            src="assets/icons/contact/how-to-red.svg"
                            alt="fax"
                        />
                        <div>ขั้นตอนการเรียกร้องสินไหมทดแทน</div>
                    </div>
                </a>
            </div>
            <div className="info">
                <a
                    onClick={() => {
                        trackEvent(
                            'Contact Us',
                            'Click Website Policy Service',
                        );

                        setTimeout(() => {
                            window.location.href =
                                'https://www.prudential.co.th/corp/prudential-th/th/policy-service/';
                        }, 500);
                    }}
                    style={{ color: '#707070' }}
                >
                    <div className="info-item">
                        <img
                            src="assets/icons/contact/service-red.svg"
                            alt="fax"
                        />
                        <div>การให้บริการด้านกรมธรรม์</div>
                    </div>
                </a>
            </div>

            <div className="text-center">
                <a
                    onClick={() => {
                        trackEvent('Contact Us', 'Click Website FAQ');

                        setTimeout(() => {
                            window.location.href =
                                'https://www.prudential.co.th/corp/prudential-th/th/support/';
                        }, 500);
                    }}
                >
                    <img
                        src="assets/icons/contact/faq.png"
                        alt="faq"
                        className="img-faq"
                    />
                </a>
            </div>
        </div>
    );
}
export default ContactUs;
