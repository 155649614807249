import React, { useEffect } from 'react';
import liff from '@line/liff';

import { config } from '../config';
import { useRecoilState } from 'recoil';
import { maintenenceGlobalState, userLineState } from '../state';
const liffId = config.liff_id.login;
const liffUrl = `https://liff.line.me/1557349447-nMQALjVW`;
// Running by Cypress, then use Stubbed LIFF SDK

if (window.Cypress) {
    window.liff = window.Cypress.liffMock;
} else {
    window.liff = liff;
}

const urlParts = window.location.pathname.split('/');
const base = urlParts.slice(0, urlParts.length - 1).join('/');

//mock bypass
// const userId = 'U9cbc4058cf054f72d266ce08430b3cb5'; //params.get('id');
// U9cbc4058cf054f72d266ce08430b3cb5
// Ua71dcd07a7057fe9eae020129940d3c9
const Login = ({ history }) => {
    const [maintenenceGlobal, setMaintenenceGlobal] = useRecoilState(
        maintenenceGlobalState,
    );
    const [userLine, setUserLine] = useRecoilState(userLineState);

    useEffect(() => {
        const initialize = async () => {
            if (liff.isInClient()) {
                try {
                    await liff.init({
                        liffId,
                        withLoginOnExternalBrowser: true,
                    });

                    let profile = await liff.getProfile();

                    setUserLine(profile);
                    window.location = `${config.pruid_login}?s=line&lineId=${profile.userId}`;
                } catch (error) {
                    history.push(`${base}/not-found`);
                }
            } else {
                history.push(`${base}/not-found`);
            }
        };

        if (!userLine?.userId) {
            if (!maintenenceGlobal?.isMaintenance) {
                initialize();
            }
        }
    }, []);

    return <></>;
};

export default Login;
