import ReactDOM from 'react-dom';
import { useEffect } from 'react';
// import { useRecoilSnapshot } from 'recoil';
import { Provider } from 'react-redux';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import './i18n';
import store from './store';
import App from './App';
import { RecoilDevTools as RecoilToolKit } from 'recoil-toolkit';
import * as serviceWorker from './serviceWorker';
import { config } from './config';

const root = document.getElementById('root');
let countSnapshot = 0
const DebugObserver = () => {
  const snapshot = useRecoilSnapshot();
  useEffect(
    () => {
      countSnapshot = countSnapshot + 1
      for (const node of snapshot.getNodes_UNSTABLE({
        isModified: true
      })) {
        // console.debug(node.key, snapshot.getLoadable(node));
        console.debug(`Count ${countSnapshot} Atoms modified:`, node.key, snapshot.getLoadable(node).contents);
      }
    }, [snapshot]
  );

  return null;
};

ReactDOM.render(
  <Provider store={store}>
    <RecoilRoot>
      {
        config.mode !== 'prd' && <>
          <DebugObserver />
          <RecoilToolKit forceSerialize={false} />
        </>
      }
      <App />
    </RecoilRoot>
  </Provider>,
  root
);

serviceWorker.unregister();
