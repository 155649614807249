export const getParameterByName = (url, name) => {
    const match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

export const objectToQueryString = (object) => {
    Object.keys(object).forEach(function (key) {
        if (object[key] === undefined || object[key] === null) {
            delete object[key];
        }
    });

    return new URLSearchParams(object)
}