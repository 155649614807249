import React, { Fragment } from 'react';
import { Card, CardTitle } from 'reactstrap';

export default class NotFound extends React.PureComponent {
    render() {
        return (
            <Fragment>
                <Card>
                    <CardTitle className="text-center">
                        <img src="assets/icons/warning/server-error.svg" alt="not found" className="img-error" />

                        <p data-cy="not-found" className="h2">พบข้อผิดพลาดของระบบ</p>
                        
                        <p>โปรดติดต่อเจ้าหน้าที่เพื่อทำการแก้ไข</p>
                    </CardTitle>
                </Card>
            </Fragment>
        )
    }
};