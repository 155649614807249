import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const Policy = Loadable({
    loader: () => import('./Policy'),
    loading: () => null,
});

const PolicyDetail = Loadable({
    loader: () => import('./PolicyDetail'),
    loading: () => null,
});

const TtbTheTeasureTC = Loadable({
    loader: () => import('./TtbTheTeasureTC'),
    loading: () => null,
});

const TtbTheTeasureForm = Loadable({
    loader: () => import('./TtbTheTeasureForm'),
    loading: () => null,
});

const TtbTheTeasureComplete = Loadable({
    loader: () => import('./TtbTheTeasureComplete'),
    loading: () => null,
});

const TtbTheTeasureSummary = Loadable({
    loader: () => import('./TtbTheTeasureSummary'),
    loading: () => null,
});

const TtbTheTeasureOtp = Loadable({
    loader: () => import('./TtbTheTeasureOtp'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});

export default function PolicyRoute() {
    return (
        <Switch>
            <Route exact path={`/Mypolicy`} component={Policy} />
            <Route
                path={`/Mypolicy/Mypolicy-detail`}
                component={PolicyDetail}
            />
            <Route path={`/Mypolicy/consent-TC`} component={TtbTheTeasureTC} />
            <Route
                path={`/Mypolicy/ttb-the-teasure/form`}
                component={TtbTheTeasureForm}
            />
            <Route
                path={`/Mypolicy/ttb-the-teasure/complete`}
                component={TtbTheTeasureComplete}
            />
            <Route
                path={`/Mypolicy/ttb-the-teasure/summary`}
                component={TtbTheTeasureSummary}
            />

            <Route
                path={`/Mypolicy/ttb-the-teasure/confirmation-otp`}
                component={TtbTheTeasureOtp}
            />

            <Route component={NotFound} />
        </Switch>
    );
}
