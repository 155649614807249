import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const PrivilegeCheck = Loadable({
    loader: () => import('./PrivilegeCheck'),
    loading: () => null,
});

// const NotFound = Loadable({
//     loader: () => import('../NotFound'),
//     loading: () =>null,
// });

export default function PrivilegeRoute() {
    return (
        <Switch>
            <Route exact path={`/privilege`} component={PrivilegeCheck} />

            {/* <Route component={NotFound} /> */}
        </Switch>
    );
}
