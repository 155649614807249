import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardTitle, Button, Spinner } from 'reactstrap';
// import { FiSettings } from 'react-icons/fi';
import { connect } from 'react-redux';
import liff from '@line/liff';
import { withTranslation } from 'react-i18next';

import { trackUser, trackPageView, trackEvent } from '../../utils/analytics';
import { useRecoilState } from 'recoil';
import { config } from '../../config';
import {
    logout,
    rawHttpGet,
    getUserSetting,
    updateUserSetting,
    getAccountMapping,
} from '../../actions';
import { SERVICE_API } from '../../actions/constants';

// import Switch from '../../components/switch';
import Confirm from '../../components/confirm';
import { cmsMaintenanceFlagState, userLineState, userState } from '../../state';
import { maintenenceGlobalState } from '../../state';

const liffId = config.liff_id.setting;
const liffUrl = `https://liff.line.me/${liffId}`;
// Running by Cypress, then use Stubbed LIFF SDK
if (window.Cypress) {
    window.liff = window.Cypress.liffMock;
} else {
    window.liff = liff;
}

const Setting = props => {
    const { t, loading, userDetail, history } = props;
    let userSetting = userDetail;
    const params = new URLSearchParams(props?.location?.search);
    //mock bypass
    // const userId = 'U9cbc4058cf054f72d266ce08430b3cb5'; //params.get('id');
    // U9cbc4058cf054f72d266ce08430b3cb5
    // Ua71dcd07a7057fe9eae020129940d3c9

    const userId = params.get('id');
    const [isError, setIsError] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isInLogout, setIsInLogout] = useState(false);
    // const [profile, setProfile] = useState(
    //     userId ? { userId } : user?.userId ? { userId: user.userId } : {},
    // );
    const [profile, setProfile] = useState(userId ? { userId } : {});
    const [userLine, setUserLine] = useRecoilState(userLineState);

    const [user, setUser] = useRecoilState(userState);
    const [maintenenceGlobal] = useRecoilState(
        maintenenceGlobalState,
    );
    const [cmsMaintenanceFlag] = useRecoilState(
        cmsMaintenanceFlagState,
    );

    useEffect(() => {
        document.getElementById('body').style.backgroundColor = '#f7f7f7';
        const initialize = async () => {
            if (liff.isInClient()) {
                // mock close
                try {
                    await liff.init({
                        liffId,
                        withLoginOnExternalBrowser: true,
                    });

                    const profile = await liff.getProfile();

                    setUserLine(profile);
                    setProfile(profile);
                    getUserDetail(profile.userId);
                } catch (error) {
                    if (profile?.userId) {
                        setUserLine(profile);
                        getUserDetail(profile.userId);
                    } else {
                        props.history.push(`/not-found`);
                    }
                }
                // end
            } else {
                if (config.mode === 'dev') {
                    await liff.init({
                        liffId,
                        withLoginOnExternalBrowser: true,
                    });
                    if (liff.isLoggedIn()) {
                        const profile = await liff.getProfile();

                        setUserLine(profile);
                        setProfile(profile);
                        getUserDetail(profile.userId);
                    } else {
                        liff.login({ redirectUri: window.location.href });
                    }
                } else {
                    setUserLine(profile);
                    getUserDetail(profile.userId);
                }
            }
        };

        if (!userLine?.userId) {
            if (!maintenenceGlobal?.isMaintenance) {
                initialize();
            }
        }
    }, []);

    const getUserDetail = async _userId => {
        const resultUser = await getAccountMapping(_userId);
        if (resultUser?.data && resultUser?.status === 'OK') {
            trackUser(resultUser.data);
            trackPageView();

            setUser(resultUser.data)
        } else {
            window.location.href = `${config.pruid_login}&c=${liffUrl}`;
        }

        // get user setting
        props.getUserSetting(_userId);
    };

    // const handleChangeOtpIn = value => {
    //     const { updateUserSetting } = props;
    //     const payload = {
    //         premium_reminder: value,
    //         thai_id: user?.username || '',
    //         _csrf: user?.csrfToken || '',
    //     };

    //     // dispatch call api to update opt-in
    //     updateUserSetting(profile.userId, payload).then(({ json }) => {
    //         if (json.error) {
    //             setIsError(true);
    //         } else {
    //             trackEvent(
    //                 'Setting',
    //                 'รับข้อความแจ้งเตือนต่างๆ : ' + (value ? 'Y' : 'N'),
    //             );
    //         }
    //     });
    // };

    const handleClickLogout = () => {
        setIsConfirmOpen(!isConfirmOpen);
    };

    const onConfirmLogout = () => {
        setIsInLogout(true);

        // call api to logout PRUid
        setTimeout(() => {
            logout(profile.userId)
                .then(async () => {
                    trackEvent('Setting', 'ออกจากระบบ');

                    // Call api chatbot logout
                    const payload = {
                        eventname: 'logout',
                        cutomerid: user?.username,
                        lineid: profile.userId,
                    };

                    const options = {
                        method: 'post',
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${config.chatbot.api_key}`,
                        },
                        body: JSON.stringify(payload),
                    };

                    await rawHttpGet(
                        config.chatbot.api_endpoint,
                        options,
                    );

                    setIsInLogout(false);
                    setIsConfirmOpen(false);

                    if (window.liff.isInClient()) {
                        window.liff.closeWindow();
                    }
                })
                .catch(error => {
                    setIsInLogout(false);
                    setIsConfirmOpen(false);
                });
        }, 1000);
    };

    if (loading || !userDetail) {
        return (
            <Fragment>
                <div className="text-center" style={{ marginTop: 40 }}>
                    <Spinner color="primary" />
                </div>
            </Fragment>
        );
    }

    if (userSetting && !userSetting.data) {
        userSetting = {
            ...userSetting,
            data: {},
        };
    }

    return (
        <Fragment>
            {/* Confirm modal */}
            <Confirm
                isOpen={isConfirmOpen}
                onConfirm={onConfirmLogout}
                onCancel={handleClickLogout}
                type="logout"
            />

            {/* Error modal */}
            <Confirm
                isOpen={isError}
                onConfirm={() => setIsError(false)}
                type="error"
            />

            {/* Setting */}
            {/* <Card style={{ borderRadius: 20 }}>
                <CardTitle>
                    <p
                        data-cy="title-text"
                        className="h5 d-flex justify-content-start align-items-center"
                        style={{ marginTop: 10 }}
                    >
                        <FiSettings style={{ marginRight: 5 }} />
                        {t('setting')}
                    </p>

                    <Switch
                        label={t('notify')}
                        checked={userSetting.data.premiumReminder}
                        onChange={handleChangeOtpIn}
                    />
                </CardTitle>
            </Card> */}

            <Card style={{ borderRadius: 20 }}>
                <CardTitle style={{ paddingRight: 10 }}>
                    <a
                        onClick={() => {
                            trackEvent(
                                'Setting',
                                'Click Website Privacy notice',
                            );

                            setTimeout(() => {
                                window.location.href =
                                    'https://www.prudential.co.th/corp/prudential-th/th/privacy-notice/';
                            }, 500);
                        }}
                        target="_blank"
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            color: '#707070',
                            textDecoration: 'none',
                        }}
                    >
                        <span style={{ flex: 1 }}>{t('privacy')}</span>
                        <img
                            data-v-115389dc=""
                            src="data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiNlZDI2MmUiPjxwYXRoIGQ9Ik02OC44LDE1NC44aC0xMS40NjY2N2MtMi4yMTMwNywwIC00LjIzMTIsLTEuMjc4NTMgLTUuMTgyOTMsLTMuMjc5NDdjLTAuOTUxNzMsLTIuMDAwOTMgLTAuNjU5MzMsLTQuMzY4OCAwLjc0NTMzLC02LjA4ODhsNDguNjMwMTMsLTU5LjQzMTczbC00OC42MzAxMywtNTkuNDM3NDdjLTEuNDA0NjcsLTEuNzE0MjcgLTEuNjkxMzMsLTQuMDgyMTMgLTAuNzQ1MzMsLTYuMDg4OGMwLjk0NiwtMi4wMDY2NyAyLjk2OTg3LC0zLjI3MzczIDUuMTgyOTMsLTMuMjczNzNoMTEuNDY2NjdjMS43MiwwIDMuMzQ4MjcsMC43NzQgNC40Mzc2LDIuMTA0MTNsNTEuNiw2My4wNjY2N2MxLjcyNTczLDIuMTE1NiAxLjcyNTczLDUuMTQ4NTMgMCw3LjI2NDEzbC01MS42LDYzLjA2NjY3Yy0xLjA4OTMzLDEuMzI0NCAtMi43MTc2LDIuMDk4NCAtNC40Mzc2LDIuMDk4NHoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg=="
                            alt="icon"
                            width="30"
                            height="30"
                        />
                    </a>
                </CardTitle>
            </Card>
            {
                !cmsMaintenanceFlag && <Card style={{ borderRadius: 20 }}>
                    <CardTitle style={{ paddingRight: 10 }}>
                        <a
                            onClick={() => {
                                trackEvent('Setting', 'Click Edit Consent');

                                history.push(`/setting/consent`);
                            }}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                color: '#707070',
                                textDecoration: 'none',
                            }}
                        >
                            <span style={{ flex: 1 }}>{t('consent')}</span>
                            <img
                                data-v-115389dc=""
                                src="data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiNlZDI2MmUiPjxwYXRoIGQ9Ik02OC44LDE1NC44aC0xMS40NjY2N2MtMi4yMTMwNywwIC00LjIzMTIsLTEuMjc4NTMgLTUuMTgyOTMsLTMuMjc5NDdjLTAuOTUxNzMsLTIuMDAwOTMgLTAuNjU5MzMsLTQuMzY4OCAwLjc0NTMzLC02LjA4ODhsNDguNjMwMTMsLTU5LjQzMTczbC00OC42MzAxMywtNTkuNDM3NDdjLTEuNDA0NjcsLTEuNzE0MjcgLTEuNjkxMzMsLTQuMDgyMTMgLTAuNzQ1MzMsLTYuMDg4OGMwLjk0NiwtMi4wMDY2NyAyLjk2OTg3LC0zLjI3MzczIDUuMTgyOTMsLTMuMjczNzNoMTEuNDY2NjdjMS43MiwwIDMuMzQ4MjcsMC43NzQgNC40Mzc2LDIuMTA0MTNsNTEuNiw2My4wNjY2N2MxLjcyNTczLDIuMTE1NiAxLjcyNTczLDUuMTQ4NTMgMCw3LjI2NDEzbC01MS42LDYzLjA2NjY3Yy0xLjA4OTMzLDEuMzI0NCAtMi43MTc2LDIuMDk4NCAtNC40Mzc2LDIuMDk4NHoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg=="
                                alt="icon"
                                width="30"
                                height="30"
                            />
                        </a>
                    </CardTitle>
                </Card>
            }

            {/* Logout */}
            <div className="text-center">
                <Button
                    data-cy="logout"
                    outline
                    block
                    color="secondary"
                    style={{ margin: '20px 0', borderRadius: 12 }}
                    onClick={() => handleClickLogout()}
                    disabled={isInLogout}
                >
                    {isInLogout ? (
                        <Spinner size="sm" color="dark" />
                    ) : (
                        t('logout')
                    )}
                </Button>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    loading: state.loadingUser,
    userDetail: state.userDetail,
});

const mapDispatchToProps = {
    getUserSetting,
    updateUserSetting,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Setting));
