import React from 'react';
import { Modal, ModalBody, Container, Button } from 'reactstrap';

import './style.scss';

export default ({ type, isOpen, text, btnText, onClick }) => {
    const icon = type === 'warning' ? 'warning-icon.svg' : 'danger-icon.svg';

    return (
        <Modal isOpen={isOpen} className="modal-nested modal-popups">
            <ModalBody>
                <Container>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={`/assets/icons/${icon}`} alt={text} />
                        <p>{text}</p>
                    </div>

                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <Button
                            color="primary"
                            style={{
                                borderRadius: 30,
                                maxWidth: 200,
                            }}
                            onClick={onClick}
                        >
                            {btnText}
                        </Button>
                    </div>
                    <br />
                </Container>
            </ModalBody>
        </Modal>
    );
};
