import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const PaymentPremium = Loadable({
    loader: () => import('./PaymentPremium'),
    loading: () => null,
});

const PaymentPremiumForm = Loadable({
    loader: () => import('./PaymentPremiumForm'),
    loading: () => null,
});

const PaymentPremiumComplete = Loadable({
    loader: () => import('./PaymentPremiumComplete'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});

export default function PaymentPremiumRoute() {
    return (
        <Switch>
            <Route exact path={`/payment-premium`} component={PaymentPremium} />
            <Route
                path={`/payment-premium/form/:policyCertNo`}
                component={PaymentPremiumForm}
            />
            <Route
                path={`/payment-premium/complete`}
                component={PaymentPremiumComplete}
            />
            <Route component={NotFound} />
        </Switch>
    );
}
