import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const PaymentDebit = Loadable({
    loader: () => import('./PaymentDebit'),
    loading: () => null,
});

const PaymentDebitMethod = Loadable({
    loader: () => import('./PaymentDebitMethod'),
    loading: () => null,
});

const PaymentDebitAccount = Loadable({
    loader: () => import('./PaymentDebitAccount'),
    loading: () => null,
});

const PaymentCreditCard = Loadable({
    loader: () => import('./PaymentCreditCard'),
    loading: () => null,
});

const PaymentCreditCardForm = Loadable({
    loader: () => import('./PaymentCreditCard/form'),
    loading: () => null,
});

const PaymentCreditCardOtp = Loadable({
    loader: () => import('./PaymentCreditCard/otp'),
    loading: () => null,
});

const PaymentCreditCardComplete = Loadable({
    loader: () => import('./PaymentCreditCard/complete'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});

export default function PaymentDebitRoute() {
    return (
        <Switch>
            <Route
                exact
                path={`/payment-directdebit`}
                component={PaymentDebit}
            />
            <Route
                path={`/payment-directdebit/select-method`}
                component={PaymentDebitMethod}
            />
            <Route
                path={`/payment-directdebit/bank-account`}
                component={PaymentDebitAccount}
            />
            <Route
                path={`/payment-directdebit/credit-card-tc`}
                component={PaymentCreditCard}
            />
            <Route
                path={`/payment-directdebit/credit-card-form`}
                component={PaymentCreditCardForm}
            />
            <Route
                path={`/payment-directdebit/credit-card-otp`}
                component={PaymentCreditCardOtp}
            />
            <Route
                path={`/payment-directdebit/credit-card-complete`}
                component={PaymentCreditCardComplete}
            />
            <Route component={NotFound} />
        </Switch>
    );
}
