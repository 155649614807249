import { atom } from 'recoil';
import { taxConsentState } from './taxConsentState';

const userState = atom({
    key: 'userState',
    default: {},
});

const userLineState = atom({
    key: 'userLineState',
    default: {},
});

const policyListState = atom({
    key: 'policyListState',
    default: [],
});

const productTypeState = atom({
    key: 'productTypeState',
    default: 'all',
});

// new
const claimPolicyListState = atom({
    key: 'claimPolicyListState',
    default: [],
});

const policyPendingListState = atom({
    key: 'policyPendingListState',
    default: [],
});

const checkPolicyListState = atom({
    key: 'checkPolicyListState',
    default: null,
});

const policySelectedState = atom({
    key: 'policySelectedState',
    default: [],
});

const policySelectedOneState = atom({
    key: 'policySelectedOneState',
    default: null,
});

const policySelectedOneTtbTheTeasureState = atom({
    key: 'policySelectedOneTtbTheTeasureState',
    default: null,
});
const policyDetailTheTeasureState = atom({
    key: 'policyDetailTheTeasureState',
    default: null,
});

const costTypeListState = atom({
    key: 'costTypeListState',
    default: [],
});

const relationshipListtState = atom({
    key: 'relationshipListtState',
    default: [],
});

const bankListState = atom({
    key: 'bankListState',
    default: {},
});

const policySelectedCancelState = atom({
    key: 'policySelectedCancelState',
    default: null,
});

const policyConsentState = atom({
    key: 'policyConsentState',
    default: null,
});

const paymentState = atom({
    key: 'paymentState',
    default: {},
});
const sumaryPaymentTtbState = atom({
    key: 'sumaryPaymentTtbState',
    default: {},
});

const claimListState = atom({
    key: 'claimListState',
    default: [],
});

const claimReasonState = atom({
    key: 'claimReasonState',
    default: [],
});

const claimBankListState = atom({
    key: 'claimBankListState',
    default: [],
});

const claimBankAccountState = atom({
    key: 'claimBankAccountState',
    default: null,
});

const claimHospitalState = atom({
    key: 'claimHospitalState',
    default: [],
});

const claimSicknessState = atom({
    key: 'claimSicknessState',
    default: [],
});

const accountListState = atom({
    key: 'accountListState',
    default: [],
});

const claimFormState = atom({
    key: 'claimFormState',
    default: null,
});

const payloadCheckDuplicateClaimState = atom({
    key: 'payloadCheckDuplicateClaimState',
    default: null,
});

const contactPoliciesListState = atom({
    key: 'contactPoliciesListState',
    default: [],
});

const contactFormInitialValuesState = atom({
    key: 'contactFormInitialValuesState',
    default: {},
});

const claimGYRTInsuredListState = atom({
    key: 'claimGYRTInsuredListState',
    default: [],
});

const maintenenceGlobalState = atom({
    key: 'maintenenceGlobalState',
    default: {},
});

const consentState = atom({
    key: 'consentState',
    default: {},
});

const cmsMaintenanceFlagState = atom({
    key: 'cmsMaintenanceFlagState',
    default: false,
});

export {
    userState,
    userLineState,
    policyListState,
    productTypeState,
    claimPolicyListState,
    policyPendingListState,
    checkPolicyListState,
    policySelectedState,
    policySelectedOneState,
    policySelectedCancelState,
    policyConsentState,
    paymentState,
    claimListState,
    claimReasonState,
    claimBankListState,
    claimBankAccountState,
    claimSicknessState,
    claimHospitalState,
    accountListState,
    claimFormState,
    contactPoliciesListState,
    contactFormInitialValuesState,
    claimGYRTInsuredListState,
    maintenenceGlobalState,
    sumaryPaymentTtbState,
    policySelectedOneTtbTheTeasureState,
    bankListState,
    policyDetailTheTeasureState,
    costTypeListState,
    relationshipListtState,
    taxConsentState,
    consentState,
    payloadCheckDuplicateClaimState,
    cmsMaintenanceFlagState
};
