import React from 'react';
import { Button, FormGroup, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { httpGet, httpPost } from '../../actions';
import { SERVICE_API } from '../../actions/constants';

import { markEmail, markPhone } from '../../utils/mark';

import './style.scss';

class OTP extends React.PureComponent {
    _isMounted = false;
    intervalId = null;
    intervalIdVerify = null;

    constructor(props) {
        super();

        this.state = {
            counter: props.delay,
            counterVerify: 0,
            retryVerify: 0,
            otpType: props.otpType,
            sendTo:
                props.otpType === 'email'
                    ? props.form.values.email
                    : props.form.values.mobile_phone_orig,
            refCode: null,
            isSend: true,
            isLoading: false,
            isError: false,
        };

        this.handleChangeOTP = this.handleChangeOTP.bind(this);
        this.handleFocusOTP = this.handleFocusOTP.bind(this);
        this.confirmOTP = this.confirmOTP.bind(this);
        this.clearPin = this.clearPin.bind(this);
        this.onResendOTP = this.onResendOTP.bind(this);
        this.switchOtpType = this.switchOtpType.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.onResendOTP();
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.intervalId);
        clearInterval(this.intervalIdVerify);
    }

    handleChangeOTP(e) {
        e.preventDefault();

        const { form, field } = this.props;
        const { value } = e.target;

        form.setFieldValue(field.name, value);

        this.clearPin();
        this.checkPinActive(value);
    }

    confirmOTP() {
        const {
            field: { value },
            onError,
            onSuccess,
            verifyOptions,
            autoVerifyOTP,
        } = this.props;
        let { counterVerify, retryVerify, refCode, refCodeFull } = this.state;

        retryVerify++;

        if (retryVerify >= verifyOptions.max) {
            counterVerify += verifyOptions.delay * (retryVerify - 4);
        }

        this.setState({ isLoading: true, counterVerify, retryVerify });

        if (this.intervalIdVerify) {
            clearInterval(this.intervalIdVerify);
        }

        // coundown to verify otp again
        this.intervalIdVerify = setInterval(() => {
            if (this.state.counterVerify > 0) {
                this.setState({
                    counterVerify: this.state.counterVerify - 1,
                });
            } else {
                clearInterval(this.intervalIdVerify);
            }
        }, 1000);

        setTimeout(() => {
            let isError = false;

            // call api to verify otp
            const data = {
                code: value,
                ref: refCodeFull,
            };

            if (autoVerifyOTP === false) {
                onSuccess({
                    code: value,
                    ref: refCode,
                });
                this.setState({ isLoading: false });
            } else {
                httpPost(SERVICE_API.OTP_VERIFY, data).then(json => {
                    if (json.error) {
                        isError = true;
                        onError();

                        this.setState({ isLoading: false, isError });
                    } else {
                        onSuccess({
                            code: value,
                            ref: refCode,
                        });

                        // setTimeout(() => {
                        //     this.setState({ isLoading: false });
                        // }, 500);
                    }
                });
            }
        }, 500);
    }

    checkPinActive(value) {
        const pinList = document.getElementsByClassName('pincode-input-text');

        if (!value) {
            pinList[0].className += 'active';
        } else if (pinList[value.length]) {
            pinList[value.length].className += 'active';
        }
    }

    handleFocusOTP() {
        const {
            field: { value },
        } = this.props;

        this.checkPinActive(value);
    }

    clearPin() {
        const pinList = document.getElementsByClassName('pincode-input-text');

        for (let i = 0; i < pinList.length; i++) {
            pinList[i].className = 'pincode-input-text ';
        }
    }

    onResendOTP(e) {
        if (e) {
            e.preventDefault();
        }

        const { form, field, delay, onReset } = this.props;
        const { sendTo, otpType } = this.state;

        this.setState({ isLoading: true });

        form.setFieldValue(field.name, '');

        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        // call api to send otp
        if (!sendTo) {
            return;
        }

        this.setState({
            refCode: '',
            isError: false,
        });

        const apiRoute =
            otpType === 'email'
                ? `${SERVICE_API.OTP_SEND_EMAIL}?email=${sendTo}`
                : `${SERVICE_API.OTP_SEND_SMS}?mobile_phone=${sendTo}`;

        setTimeout(() => {
            httpGet(apiRoute).then(json => {
                // reset otp parent state
                onReset();

                this.setState({
                    counter: delay,
                    isError: false,
                });

                if (json && json.ref) {
                    // set input focus
                    this.otpInput.focus();

                    this.setState({
                        refCode: json.ref.slice(0, 6),
                        refCodeFull: json.ref,
                        isLoading: false,
                    });

                    // coundown to resend again
                    this.intervalId = setInterval(() => {
                        if (this.state.counter > 0 && this._isMounted) {
                            this.setState({
                                counter: this.state.counter - 1,
                            });
                        } else {
                            clearInterval(this.intervalId);
                        }
                    }, 1000);
                } else {
                    this.setState({ isError: true, isLoading: false });
                }
            });
        }, 500);
    }

    switchOtpType() {
        const { form, onChangeType } = this.props;
        let { otpType } = this.state;

        otpType = otpType === 'email' ? 'mobile' : 'email';

        onChangeType(otpType);

        this.setState({
            otpType,
            sendTo:
                otpType === 'email'
                    ? form.values.email
                    : form.values.mobile_phone_orig,
        });

        setTimeout(() => this.onResendOTP(), 500);
    }

    render() {
        const { t, field, display, maxLength } = this.props;
        const {
            counter,
            counterVerify,
            otpType,
            sendTo,
            refCode,
            isLoading,
            isError,
        } = this.state;

        const pin = field.value;
        const pinBox = [];

        // create pin box
        for (let i = 0; i < maxLength; i++) {
            pinBox.push(
                <div className="pincode-input-text " key={i}>
                    {pin && pin[i] && (display || '*')}
                </div>,
            );
        }

        // Form OTP
        return (
            <FormGroup>
                <div className="text-center">
                    <p>
                        {t('otp_title')}
                        <br />
                        {t('otp_title2')}
                        {otpType === 'email' ? t('email') : t('telephone')}{' '}
                        {otpType === 'email' ? (
                            <span
                                className="mark-star"
                                dangerouslySetInnerHTML={{
                                    __html: `<span>${markEmail(sendTo)}</span>`,
                                }}
                            ></span>
                        ) : (
                            <span
                                className="mark-star"
                                dangerouslySetInnerHTML={{
                                    __html: `<span>${markPhone(sendTo)}</span>`,
                                }}
                            ></span>
                        )}
                        <br />
                        {t('your_regis')}
                    </p>
                </div>

                {/* OTP referance */}
                <p>
                    {t('ref')} : {refCode}
                </p>

                {/* Pin input */}
                <div className="otp-container">
                    <div className="otp-wrap">
                        <div className="pincode-input-container">
                            <input
                                {...field}
                                ref={otpInput => (this.otpInput = otpInput)}
                                id={field.name}
                                className="input-mask"
                                type="tel"
                                autoComplete="one-time-code"
                                maxLength={maxLength}
                                value={field.value || ''}
                                onChange={this.handleChangeOTP}
                                onFocus={this.handleFocusOTP}
                                onBlur={this.clearPin}
                                disabled={!refCode}
                            />
                            {pinBox}
                        </div>
                    </div>

                    {/* Error */}
                    {isError && (
                        <>
                            <p
                                data-cy="warning-message"
                                className="text-danger"
                            >
                                {refCode
                                    ? t('error_otp')
                                    : t('error_something')}
                            </p>
                        </>
                    )}

                    {/* Request OTP again */}
                    <div>
                        {t('otp_notfound')}
                        <Button
                            id="request-otp"
                            color="link"
                            className="text-info"
                            disabled={counter > 0 || isLoading}
                            onClick={this.onResendOTP}
                        >
                            {counter > 0 && !isLoading
                                ? t('otp_wait')
                                : t('otp_send_again')}
                        </Button>
                    </div>

                    <div className="text-center" style={{ width: '100%' }}>
                        {/* Verify OTP */}
                        <Button
                            color="primary"
                            disabled={
                                pin.length !== maxLength ||
                                counterVerify > 0 ||
                                isLoading ||
                                this.props.outsideLoader
                            }
                            style={{ margin: '15px 0' }}
                            onClick={this.confirmOTP}
                        >
                            {isLoading || !refCode || this.props.outsideLoader ? (
                                <Spinner size="sm" color="info" />
                            ) : (
                                <>
                                    {counterVerify > 0
                                        ? `${t(
                                            'confirm_in',
                                        )} ${counterVerify} ${t('sec')}`
                                        : t('confirm')}
                                </>
                            )}
                        </Button>

                        <br />

                        {/* Switch method */}
                        <Button
                            data-cy="btn-cancel-otp"
                            color="link"
                            onClick={this.switchOtpType}
                        >
                            <u className="text-primary">
                                {otpType === 'email'
                                    ? t('otp_telephone')
                                    : t('otp_email')}
                            </u>
                        </Button>
                    </div>
                </div>
            </FormGroup>
        );
    }
}

export default withTranslation()(OTP);
