import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const PolicyLoan = Loadable({
    loader: () => import('./PolicyLoan'),
    loading: () => null,
});

const PolicyLoanPending = Loadable({
    loader: () => import('./PolicyLoanPending'),
    loading: () => null,
});

const PolicyLoanAbout = Loadable({
    loader: () => import('./PolicyLoanAbout'),
    loading: () => null,
});

const PolicyLoanConsent = Loadable({
    loader: () => import('./PolicyLoanConsent'),
    loading: () => null,
});

const PolicyLoanForm = Loadable({
    loader: () => import('./PolicyLoanForm'),
    loading: () => null,
});

const PolicyLoanSummary = Loadable({
    loader: () => import('./PolicyLoanSummary'),
    loading: () => null,
});

const PolicyLoanOtp = Loadable({
    loader: () => import('./PolicyLoanOtp'),
    loading: () => null,
});
const PolicyLoanCancelOtp = Loadable({
    loader: () => import('./PolicyLoanCancelOtp'),
    loading: () => null,
});

const PolicyLoanCancelComplete = Loadable({
    loader: () => import('./PolicyLoanCancelComplete'),
    loading: () => null,
});

const PolicyLoanComplete = Loadable({
    loader: () => import('./PolicyLoanComplete'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});

export default function PolicyLoanRoute() {
    return (
        <Switch>
            <Route exact path={`/policy-loan`} component={PolicyLoan} />
            <Route
                path={`/policy-loan/policy-pending`}
                component={PolicyLoanPending}
            />
            <Route
                path={`/policy-loan/tooltip-information`}
                component={PolicyLoanAbout}
            />
            <Route
                path={`/policy-loan/consent-TC`}
                component={PolicyLoanConsent}
            />
            <Route
                path={`/policy-loan/fillin-form`}
                component={PolicyLoanForm}
            />
            <Route
                path={`/policy-loan/summary`}
                component={PolicyLoanSummary}
            />
            <Route
                path={`/policy-loan/confirmation-otp`}
                component={PolicyLoanOtp}
            />
            <Route
                path={`/policy-loan/cancel-otp`}
                component={PolicyLoanCancelOtp}
            />
            <Route
                path={`/policy-loan/cancel-complete`}
                component={PolicyLoanCancelComplete}
            />
            <Route
                path={`/policy-loan/complete`}
                component={PolicyLoanComplete}
            />
            <Route component={NotFound} />
        </Switch>
    );
}
