import mixpanel from 'mixpanel-browser';

export const initMix = token => {
    mixpanel.init(token, { debug: true });
};

export const setUserMix = user => {
    if (user) {
        mixpanel.identify(user.username);
        mixpanel.people.set({
            $name: user.username,
            $username: user.username,
            $email: user.email,
            $phone: user.mobile_no,
            $direct: 'LINE',
            $initial_referrer: 'LINE',
            $initial_referring_domain: 'LINE',
            'Updated at': new Date(),
        });
    }
};

export const setPageViewMix = source => {
    mixpanel.track(window.location.pathname + (source ? ` (${source})` : ''));
};

export const setEventMix = (eventName, eventData) => {
    mixpanel.track(eventName, eventData || {});
};
