import ReactGA from 'react-ga';

export const initGA = trackingId => {
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname);
};

export const setPageViewGA = () => {
    ReactGA.pageview(window.location.pathname);
};

export const setEventGA = (category, action) => {
    ReactGA.event({ category, action });
};
