import { setUserMix, setPageViewMix, setEventMix } from './mixpanel';
import { setEventGA } from './ga';

export const trackUser = data => {
    // Mixpanel
    setUserMix(data);
};

export const trackPageView = source => {
    // Mixpanel
    setPageViewMix(source);
};

export const trackEvent = (label, event, data) => {
    // Mixpanel
    setEventMix(event, data);

    // GA
    setEventGA(label, event);
};