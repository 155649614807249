import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const ClaimPDPA = Loadable({
    loader: () => import('./ClaimPDPA'),
    loading: () => null,
});

const ClaimList = Loadable({
    loader: () => import('./ClaimList'),
    loading: () => null,
});

const ClaimForm = Loadable({
    loader: () => import('./ClaimForm'),
    loading: () => null,
});

const ClaimTC = Loadable({
    loader: () => import('./ClaimTC'),
    loading: () => null,
});

const ClaimComplete = Loadable({
    loader: () => import('./ClaimComplete'),
    loading: () => null,
});

const ClaimReject = Loadable({
    loader: () => import('./ClaimReject'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});

export default function EclaimRoute() {
    return (
        <Switch>
            <Route exact path={`/eclaim`} component={ClaimPDPA} />
            <Route path={`/eclaim/mainpage`} component={ClaimList} />
            <Route path={`/eclaim/form`} component={ClaimForm} />
            <Route path={`/eclaim/tc`} component={ClaimTC} />
            <Route path={`/eclaim/complete`} component={ClaimComplete} />
            <Route path={`/eclaim/reject`} component={ClaimReject} />
            <Route component={NotFound} />
        </Switch>
    );
}
