import React from 'react';
import { Card, CardTitle } from 'reactstrap';

const MaintenancePage = props => {
    const params = new URLSearchParams(props.location.search);
    const maintenanceMessage = params.get('msg');
    return (
        <Card>
            <CardTitle className="text-center">
                <img
                    src="assets/icons/warning/server-error.svg"
                    alt="MaintenancePage"
                    className="img-error"
                />

                <p data-cy="not-found" className="h2">
                    ระบบกำลังปิดปรับปรุง
                </p>

                <p>{maintenanceMessage}</p>
            </CardTitle>
        </Card>
    );
};
export default MaintenancePage;
