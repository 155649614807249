import React, { Fragment } from 'react';
import { Container } from 'reactstrap';

import './style.scss';
import { appVersion, config } from '../../config';
import Header from './Header';

const whitelist = [
    '/contact-us',
    '/Mypolicy/Mypolicy-detail',
    '/payment-directdebit/bank-account',
    '/d/contact-us',
    '/d/Mypolicy/Mypolicy-detail',
    '/d/payment-directdebit/bank-account',
    '/persona-health',
];

const Layout = props => {
    const { currentPage } = props;
    const styles = {
        backgroundColor: whitelist.indexOf(currentPage) !== -1 ? '#fff' : '',
    };

    const CheckIgnoreHeaderPages = () => {
        let modifyHeader =
            currentPage === '/login' ||
            currentPage === '/index-level-table' ||
            currentPage === '/search-partner/agent' ||
            currentPage === '/search-partner/broker';
        return modifyHeader ? <></> : <Header {...props} />;
    };

    const CheckIgnoreFooterPages = () => {
        let modifyHeader =
            currentPage === '/index-level-table' ||
            currentPage === '/search-partner/agent' ||
            currentPage === '/search-partner/broker' ||
            currentPage === '/tx';
        return modifyHeader ? (
            <></>
        ) : (
            <>
                <footer style={styles}>
                    <span>
                        v{appVersion} {config.mode !== 'prd' && config.mode}
                    </span>
                </footer>
            </>
        );
    };

    return (
        <Fragment>
            {CheckIgnoreHeaderPages()}
            {currentPage !== '/index-level-table' ? (
                <main id="main" style={styles}>
                    <Container>{props.children}</Container>
                </main>
            ) : (
                <div>
                    <Fragment>{props.children}</Fragment>
                </div>
            )}
            {CheckIgnoreFooterPages()}
        </Fragment>
    );
};

export default Layout;
