import React, { useState, useEffect } from 'react';
import { getMaintenencePCFlag } from '../../actions';
import { useRecoilState } from 'recoil';
import { maintenenceGlobalState } from '../../state';
import { useHistory } from 'react-router-dom';

const MaintenenceHook = props => {
    const [loading, setLoading] = useState(true);
    const [, setMaintenenceGlobal] = useRecoilState(
        maintenenceGlobalState,
    );
    const routePath = useHistory();

    useEffect(() => {
        getMaintenence();
    }, [routePath.location.pathname]);

    const getMaintenence = async () => {
        try {
            setMaintenenceGlobal({});
            const resultMaintenence = await getMaintenencePCFlag();
            if (resultMaintenence?.data && resultMaintenence?.status === 'OK') {
                if (resultMaintenence?.data?.isMaintenance) {
                    // if (1) {
                    const url = `/maintenence?msg=${resultMaintenence?.data?.maintenanceMessage}`;
                    // let mock = {
                    //     isMaintenance: true,
                    //     maintenanceMessage: 'test maintenence service',
                    // };
                    setMaintenenceGlobal(resultMaintenence?.data);
                    // setMaintenenceGlobal({ ...mock });
                    setTimeout(() => {
                        setLoading(false);
                        routePath?.push(url);
                    }, 200);
                } else {
                    return setTimeout(() => {
                        setMaintenenceGlobal({});
                        setLoading(false);
                    }, 200);
                }
            } else {
                return setTimeout(() => {
                    setMaintenenceGlobal({});
                    setLoading(false);
                }, 200);
            }
        } catch (error) {
            setTimeout(() => {
                setMaintenenceGlobal({});
                setLoading(false);
            }, 200);
        }
    };

    if (loading) {
        return (
            <>
                <div className="text-center" style={{ margin: '40px 0' }}>
                    <div className="loading"></div>
                </div>
            </>
        );
    }
    return <>{props?.children}</>;
};

export default MaintenenceHook;
