import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Setting from './Setting';
import Consent from './Consent';
import ConsentOtp from './ConsentOtp';
import ConsentComplete from './ConsentComplete';
import NotFound from '../NotFound';

export default function SettingRoute() {
    return (
        <Switch>
            <Route exact path={`/setting`} component={Setting} />
            <Route path={`/setting/consent`} component={Consent} />
            <Route path={`/setting/consent-otp`} component={ConsentOtp} />
            <Route
                path={`/setting/consent-complete`}
                component={ConsentComplete}
            />
            <Route component={NotFound} />
        </Switch>
    );
}
