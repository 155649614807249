import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import liff from '@line/liff';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { getUserConsents, updateUserConsents, getMaintenenceCMSFlag } from '../../actions';
import { userState, cmsMaintenanceFlagState } from '../../state';
import { trackEvent } from '../../utils/analytics';
import Loading from '../loading';

const CheckCMSNewVersionLayer = props => {
    const { i18n, t } = useTranslation();
    const [user] = useRecoilState(userState);
    const [newestConsent, setNewestConsent] = useState({})
    const [openModal, setOpenModal] = useState(false);
    const [blockScreen, setBlockScreen] = useState(true);
    const [, setCmsMaintenanceFlag] = useRecoilState(cmsMaintenanceFlagState);

    useEffect(() => {
        if (user.token) {
            checkConsentHasNewVersion()
        } else {
            setBlockScreen(false)
        }
    }, [user.token]);

    const checkConsentHasNewVersion = async () => {
        const resMaintenanceCMSFlag = await getMaintenenceCMSFlag();
        if (resMaintenanceCMSFlag.data && resMaintenanceCMSFlag.status === 'OK') {
            setCmsMaintenanceFlag(resMaintenanceCMSFlag.data.isMaintenance)
            if (resMaintenanceCMSFlag.data.isMaintenance) {
                setBlockScreen(false);
                return;
            }
        }
        const result = await getUserConsents(user.token);
        if (result.status === 'OK') {
            const filteredConsentHasNewVersion = result.data.filter(consent => parseInt(consent.lastestVersion) > parseInt(consent.version) || consent.isConsentNoAndOver60Days)
            setNewestConsent(filteredConsentHasNewVersion.reduce((obj, item) => { obj[item.name] = item; return obj; }, {}))
            if (filteredConsentHasNewVersion.length > 0) {
                setOpenModal(true)
            } else {
                setBlockScreen(false);
            }
        }
    }

    const handleChangeDMConsent = (e, name) => {
        newestConsent[name].acceptance = e.target.checked
        setNewestConsent({ ...newestConsent });
    }

    const handleSubmit = async () => {
        const data = {
            consent: {},
            name: `${user.first_name} ${user.last_name}`
        }
        for (const [key, value] of Object.entries(newestConsent)) {
            data.consent[key] = {
                id: value.id,
                acceptance: key === 'Privacy01' ? true : !!value.acceptance
            }
        }
        const result = await updateUserConsents(user.token, data)
        if (result.status === 'OK') {
            trackEvent('New notice consent', 'Update newest consent successfully')
        } else {
            trackEvent('New notice consent', 'Update newest consent failure')
        }
        setOpenModal(false);
        setBlockScreen(false);
    }


    return (
        <div>
            <Modal isOpen={openModal} className="consent-new-notice">
                <ModalBody>
                    <Form >
                        {
                            newestConsent.Privacy01 &&
                            (
                                <div>
                                    <b>{newestConsent.Privacy01.title[i18n.language]}</b>
                                    <div style={{ marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: newestConsent.Privacy01.shortContent[i18n.language] }} />
                                </div>
                            )
                        }
                        {
                            newestConsent.DM01 || newestConsent.DM02 ?
                                (
                                    <div>
                                        <b className='center'>{t('consent_new_notice.title')}</b>
                                        <div style={{ marginTop: '10px' }} >
                                            {t('consent_new_notice.subtitle')}
                                        </div>
                                    </div>
                                ) : ''
                        }
                        {
                            newestConsent.DM01 &&
                            (
                                <FormGroup
                                    check
                                    inline
                                    className="wrap-dm"
                                    onChange={(e) => handleChangeDMConsent(e, 'DM01')}
                                >
                                    <Input type="checkbox" />
                                    <Label check>
                                        <div dangerouslySetInnerHTML={{ __html: newestConsent.DM01.shortContent[i18n.language] }} />
                                    </Label>
                                </FormGroup>
                            )
                        }
                        {
                            newestConsent.DM02 &&
                            (
                                <FormGroup
                                    check
                                    inline
                                    className="wrap-dm"
                                    onChange={(e) => handleChangeDMConsent(e, 'DM02')}
                                >
                                    <Input type="checkbox" />
                                    <Label check>
                                        <div dangerouslySetInnerHTML={{ __html: newestConsent.DM02.shortContent[i18n.language] }} />
                                    </Label>
                                </FormGroup>
                            )
                        }
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { liff.closeWindow() }}>
                        {t('consent_new_notice.cancel')}
                    </Button>
                    <Button color="primary" onClick={handleSubmit}>
                        {t('consent_new_notice.confirm')}
                    </Button>{' '}
                </ModalFooter>
            </Modal>
            {
                blockScreen ? <Loading /> : props?.children
            }
        </div >
    )
}

export default CheckCMSNewVersionLayer;
