import packageInfo from '../package.json';

const ENV = window?.env || {};

export const appVersion = packageInfo.version;
export const config = {
    mode: ENV.REACT_APP_MODE || 'dev', // dev, prd
    api_endpoint:
        ENV.REACT_APP_API_ENDPOINT ||
        'https://services-dev.prudential.co.th/api',
    api_endpoint_filenet:
        ENV.REACT_APP_API_ENDPOINT_FILENET ||
        'https://services-dev.prudential.co.th/api',
    ga: ENV.REACT_APP_GA_ID || 'UA-116129900-3',
    mix: ENV.REACT_APP_MIX_TOKEN || '68c7055c2614906eb5fd66bdac08d437',
    liff_id: {
        contact: ENV.REACT_APP_LIFF_ID_CONTACT || '1557349447-bgar7KBJ',
        payment: ENV.REACT_APP_LIFF_ID_PAYMENT || '1557349447-DEOy1pBz',
        paymentBenefit:
            ENV.REACT_APP_LIFF_ID_PAYMENT_BENEFIT || '1557349447-75YdOaxq',
        login: ENV.REACT_APP_LIFF_ID_LOGIN || '1557349447-kR8DxQnY',
        setting: ENV.REACT_APP_LIFF_ID_SETTING || '1557349447-pg4vePGl',
        policy: ENV.REACT_APP_LIFF_ID_POLICY || '1557349447-Gjgkw9jE',
        policyDetail:
            ENV.REACT_APP_LIFF_ID_POLICY_DETAIL || '1557349447-dmX9PN2E',
        policyDetailTtbTC:
            ENV.REACT_APP_LIFF_ID_POLICY_DETAIL_TTBTC || '1557349447-baGjQJ8Z',
        policyLoan: ENV.REACT_APP_LIFF_ID_POLICY_LOAN || '1557349447-pg4vePGl',
        paymentPremium:
            ENV.REACT_APP_LIFF_ID_PAYMENT_PREMIUM || '1557349447-pg4vePGl',
        eclaim: ENV.REACT_APP_LIFF_ID_ECLAIM || '1557349447-pg4vePGl',
        personalHealth:
            ENV.REACT_APP_LIFF_ID_PERSONAL_HEALTH || '1557349447-86Wz0AdD',
        hospitalNetwork:
            ENV.REACT_APP_LIFF_ID_HOSPITAL_NETWORK || '1557349447-GvOKXdjA',
        documentsDownload:
            ENV.REACT_APP_LIFF_ID_DOCUMENT_DOWNLOAD || '1557349447-lAzaJ9gk',
        privilege: ENV.REACT_APP_LIFF_ID_PRIVILEGE || '1557349447-Qxn1NPWA',
        changeContact:
            ENV.REACT_APP_LIFF_ID_CHANGE_CONTACT || '1557349447-0ElO4p6D',
        groupEB: ENV.REACT_APP_LIFF_ID_GROUP_EB || '1557349447-zew29ZoV',
        suggestProduct: ENV.REACT_APP_LIFF_ID_SUGGEST_PRODUCT || '1557349447-5MelKA9m',
        searchPartner:
            ENV.REACT_APP_LIFF_ID_SEARCH_PARTNER || '1557349447-BPEoQaAN',
        taxConsent:
            ENV.REACT_APP_LIFF_ID_TAX_CONSENT || '1557349447-Ng8L1k0a',
    },

    pruid_login: `${ENV.REACT_APP_PRUID_LOGIN || 'https://liff.line.me/1557349447-nMQALjVW'
        }?s=PRUConnect`,

    prupolicy_fund:
        ENV.REACT_APP_PRUPOLICY_FUND ||
        'https://cwp-uat.prudential.co.th/CWPFBM2/Policydetail/fund-detail-pruconnect',

    ekyc_endpoint: {
        api: `${ENV.REACT_APP_EKYC_ENDPOINT_API ||
            'https://services-dev.prudential.co.th/d/ekyc/customer'
            }?id_number=`,
        page:
            ENV.REACT_APP_EKYC_ENDPOINT_PAGE ||
            'https://cwp-uat.prudential.co.th/ekyc/',
        token:
            ENV.REACT_APP_EKYC_ENDPOINT_TOKEN ||
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZWt5YyIsImlhdCI6MTYzMDA1MDQzNH0.0hTHhzd63dZVux7JzUe_6LRDoDNNbla6QJ04AV0tNc0',
    },
    cms: {
        tokenUrl:
            ENV.REACT_APP_CMS_TOKEN_URL ||
            'https://services-dev.prudential.co.th/d/api/cms',
        sdkUrl:
            ENV.REACT_APP_CMS_SDK_URL ||
            'https://plt-api-nprd.prudential.co.th/cms/uat/static/js/coms-ui.min.js',
        endpoint: ENV.REACT_APP_CMS_ENPOINT || 'UAT_EXT',
        clientId_pruconnect:
            ENV.REACT_APP_CMS_CLIENT_ID_PRUCONNECT ||
            '36f59a27-cec5-4387-ab43-afd42e0f576b',
        clientId_prupolicy:
            ENV.REACT_APP_CMS_CLIENT_ID_PRUPOLICY ||
            '97c73c2b-8649-468b-9afe-4b1b57ab1082',
        privacyName: ENV.REACT_APP_CMS_PRIVACY_NAME || 'Privacy01',
        consentList: ENV.REACT_APP_CMS_CONSENT_LIST || 'DM01,DM02',
    },
    chatbot: {
        api_endpoint:
            'https://communitybotbyzwizai.herokuapp.com/prudential/events',
        api_key: 'PVfmQQcM65',
    },
    auth: {
        user: 'akdfhshfs',
        pass: '28jgh37',
    },
    buzzebee: ENV.REACT_APP_BUZZEBEE || 'https://prudentialredemptioninterface.buzzebees-uat.com/auth',
    mordeeLinkUrl: 'https://bit.ly/3WJLkG1',
    groupEB:
        ENV.REACT_APP_GROUP_EB_URL || 'https://uat-pulse.eb.prudential.co.th',
    consentEditable: ['DM01', 'DM02']
};

export const google = {
    reCaptchaSiteKey: '6Ld7yrgUAAAAACV4nUFmhXxZ6b5QpAp5KKJk7mMl',
};
