import React, { Fragment } from 'react';
import { Modal, ModalBody, Container, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import './style.scss';

function Confirm({ isOpen, onConfirm, onCancel, type, text }) {
    const { t } = useTranslation();

    let message = null;

    switch (type) {
        case 'delete':
            message = (
                <Fragment>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img
                            src="assets/icons/warning/confirm-delete.svg"
                            alt="confirm delete"
                        />
                        <p data-cy="confirm-message" className="h5">
                            {t('confirm_delete')}
                        </p>
                    </div>
                </Fragment>
            );
            break;
        case 'logout':
            message = (
                <Fragment>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img
                            src="assets/icons/warning/confirm-logout.svg"
                            alt="logout"
                        />
                        <p data-cy="confirm-message" className="h5">
                            {t('confirm_logout')}
                        </p>
                    </div>
                </Fragment>
            );
            break;
        case 'error':
            message = (
                <Fragment>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img
                            src="assets/icons/warning/server-error.svg"
                            alt="server error"
                        />
                        <p data-cy="confirm-message" className="h5">
                            {text || t('error_system')}
                        </p>
                    </div>
                </Fragment>
            );
            break;
        case 'data-error':
            message = (
                <Fragment>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img
                            src="assets/icons/warning/calculate-error.svg"
                            alt="data error"
                        />
                        <p data-cy="confirm-message" className="h5">
                            {t('error_data')}
                        </p>
                    </div>
                </Fragment>
            );
            break;
        default:
            message = (
                <Fragment>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img
                            src="assets/icons/warning/calculate-error.svg"
                            alt="update data"
                        />
                        <p data-cy="confirm-message" className="h5">
                            {t('confirm_edit')}
                        </p>
                    </div>
                </Fragment>
            );
            break;
    }

    return (
        <Fragment>
            {/* Nested Modal */}
            <Modal isOpen={isOpen} className="modal-nested modal-confirm">
                <ModalBody>
                    <Container>
                        {message}

                        {/* Actions Button */}
                        {!type || (type && type.search('error')) === -1 ? (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Button
                                    data-cy="btn-confirm"
                                    color="primary"
                                    onClick={onConfirm}
                                >
                                    {t('confirm')}
                                </Button>
                                <Button
                                    data-cy="btn-confirm-cancel"
                                    color="link"
                                    onClick={onCancel}
                                >
                                    {t('cancel')}
                                </Button>
                            </div>
                        ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Button
                                    data-cy="btn-confirm"
                                    color="primary"
                                    style={{ borderRadius: 30 }}
                                    onClick={onConfirm}
                                >
                                    {t('try_again')}
                                </Button>
                            </div>
                        )}
                        <br />
                    </Container>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}

export default Confirm;

// export default class Confirm extends React.PureComponent {
//     render() {
//         const { isOpen, onConfirm, onCancel, type, text } = this.props;

//         let message = null;

//         switch (type) {
//             case 'delete':
//                 message = (
//                     <Fragment>
//                         <div className="d-flex flex-column justify-content-center align-items-center">
//                             <img
//                                 src="assets/icons/warning/confirm-delete.svg"
//                                 alt="confirm delete"
//                             />
//                             <p data-cy="confirm-message" className="h5">
//                                 โปรดยืนยัน หากคุณต้องการลบข้อมูล
//                             </p>
//                         </div>
//                     </Fragment>
//                 );
//                 break;
//             case 'logout':
//                 message = (
//                     <Fragment>
//                         <div className="d-flex flex-column justify-content-center align-items-center">
//                             <img
//                                 src="assets/icons/warning/confirm-logout.svg"
//                                 alt="logout"
//                             />
//                             <p data-cy="confirm-message" className="h5">
//                                 โปรดยืนยัน หากคุณต้องการออกจากระบบ
//                             </p>
//                         </div>
//                     </Fragment>
//                 );
//                 break;
//             case 'error':
//                 message = (
//                     <Fragment>
//                         <div className="d-flex flex-column justify-content-center align-items-center">
//                             <img
//                                 src="assets/icons/warning/server-error.svg"
//                                 alt="server error"
//                             />
//                             <p data-cy="confirm-message" className="h5">
//                                 {text || 'พบข้อผิดพลาดของระบบ'}
//                             </p>
//                         </div>
//                     </Fragment>
//                 );
//                 break;
//             case 'data-error':
//                 message = (
//                     <Fragment>
//                         <div className="d-flex flex-column justify-content-center align-items-center">
//                             <img
//                                 src="assets/icons/warning/calculate-error.svg"
//                                 alt="data error"
//                             />
//                             <p data-cy="confirm-message" className="h5">
//                                 ข้อมูลไม่ถูกต้อง
//                             </p>
//                         </div>
//                     </Fragment>
//                 );
//                 break;
//             default:
//                 message = (
//                     <Fragment>
//                         <div className="d-flex flex-column justify-content-center align-items-center">
//                             <img
//                                 src="assets/icons/warning/calculate-error.svg"
//                                 alt="update data"
//                             />
//                             <p data-cy="confirm-message" className="h5">
//                                 โปรดยืนยัน หากคุณต้องการเปลี่ยนแปลง
//                             </p>
//                         </div>
//                     </Fragment>
//                 );
//                 break;
//         }

//         return (
//             <Fragment>
//                 {/* Nested Modal */}
//                 <Modal isOpen={isOpen} className="modal-nested modal-confirm">
//                     <ModalBody>
//                         <Container>
//                             {message}

//                             {/* Actions Button */}
//                             {!type || (type && type.search('error')) === -1 ? (
//                                 <div className="d-flex flex-column justify-content-center align-items-center">
//                                     <Button
//                                         data-cy="btn-confirm"
//                                         color="primary"
//                                         onClick={onConfirm}
//                                     >
//                                         ยืนยัน
//                                     </Button>
//                                     <Button
//                                         data-cy="btn-confirm-cancel"
//                                         color="link"
//                                         onClick={onCancel}
//                                     >
//                                         ยกเลิก
//                                     </Button>
//                                 </div>
//                             ) : (
//                                 <div className="d-flex flex-column justify-content-center align-items-center">
//                                     <Button
//                                         data-cy="btn-confirm"
//                                         color="primary"
//                                         style={{ borderRadius: 30 }}
//                                         onClick={onConfirm}
//                                     >
//                                         ลองใหม่อีกครั้ง
//                                     </Button>
//                                 </div>
//                             )}
//                             <br />
//                         </Container>
//                     </ModalBody>
//                 </Modal>
//             </Fragment>
//         );
//     }
// }
