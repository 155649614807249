const PRUPOLICY = '/prupolicy';
const ECLAIM = '/claim';
const BFF = '/bff';
const FILE = '/file';
const DOPA = '/dopa';
const OTP = '/otp';
// const PRIVILEGE = '/privilege';
const MY_POLICY = '/policy';
const PREMIUM_PAYMENT = '/premium-payment';
const MASTER_DATA = '/master-data';
const LOAN = '/loan';
const SEARCH_PARTNER = '/search-partner';
const TAX_CONSENT = '/tax-consent';
const BENEFIT_PAYOUT = '/benefit-payout';
const USER = '/user';
const DIRECT_DEBIT = '/direct-debit';
const PRIVILEGE = '/privilege';


export const SERVICE_API = {
    MY_POLICY_LIST: `${MY_POLICY}/list`,
    POLICY_DETAIL: `${MY_POLICY}/:policyCertNo`,
    MY_POLICY_GET_BANK_ACCOUNT: `${MY_POLICY}/:policyNo/bank-account`,
    MY_POLICY_SUBMIT_TTB_THE_TEASURE: `${MY_POLICY}/:policyNo/reimburse-ttb-treasure-plus`,
    MY_POLICY_GET_FUND_DETAIL: `${MY_POLICY}/fund-detail/:policyNo`,
    MY_POLICY_GET_FUND_DETAIL_BY_POLICY: `${MY_POLICY}/fund-detail-by-code/:policyNo`,
    PRUPOLICY_LIST_DIRECTDEBIT: `${PRUPOLICY}/list-directdebit/:id`,
    BENEFIT_PAYOUT_POLICY_LIST: `${BENEFIT_PAYOUT}/policies`,
    PRUPOLICY_ME: `${PRUPOLICY}/me/:id`,
    USER_LOGOUT: `${USER}/logout`,
    DIRECT_DEBIT_UPDATE_PAYMENT_CREDIT_SETTING: `${DIRECT_DEBIT}/payment-credit-setting`,
    BENEFIT_PAYOUT_UPDATE_PAYMENT_BENEFIT_SETTING: `${BENEFIT_PAYOUT}/payment-benefit-setting`,
    BFF_POLICY_FULL_DETAIL: `${BFF}/policy-full-detail`,
    BFF_POLICY_DETAIL: `${BFF}/policy-detail/:id`,
    BFF_PREMIUM_DETAIL: `${BFF}/premium-detail/:id`,
    BFF_COVERAGE_DETAIL: `${BFF}/coverage-detail/:id`,
    BFF_EMEDICAL_DETAIL: `${BFF}/emedical-detail/:policyNo`,
    FILE_UPLOAD: `${FILE}/upload`,
    DOPA_CHECK_CARD_STATUS: `${DOPA}/check-card-status`,
    OTP_SEND_SMS: `${OTP}/send-sms`,
    OTP_SEND_EMAIL: `${OTP}/send-email`,
    OTP_VERIFY: `${OTP}/verify`,
    CLAIM_CHECK_MAINTENANCE: `${ECLAIM}/check-maintenance`,
    CLAIM_LIST: `${ECLAIM}/list`,
    CLAIM_POLICY_LIST: `${ECLAIM}/policy-list`,
    CLAIM_REASON: `${ECLAIM}/reasons`,
    CLAIM_BANK_LIST: `${ECLAIM}/bank-list`,
    CLAIM_BANK_ACCOUNT: `${ECLAIM}//bank-account`,
    CLAIM_HOSPITAL: `${ECLAIM}/icd10nHospital`,
    CLAIM_DUPLICATE: `${ECLAIM}/check-duplicate`,
    CLAIM_UPLOAD_DOCUMENT: `${ECLAIM}/upload-documents`,
    CLAIM_SUBMIT: `${ECLAIM}/submit`,
    CLAIM_CHECK_ACCOUNT_MAPPING: `${ECLAIM}/check-account`,
    CLAIM_GROUP_MEMBER: `${ECLAIM}/group-member`,
    PRIVILEGE_GET_TIER: `${PRIVILEGE}/tier`,
    BFF_CHANGE_CONTACT: `${BFF}/change-contact/policies`,
    BFF_CHANGE_CONTACT_VALIDATION: `${BFF}/change-contact/validation`,
    BFF_CHANGE_CONTACT_SUBMIT: `${BFF}/change-contact/submit`,
    BFF_INDEX_PRICE_LEVEL: `${BFF}/index-price-level`,
    MAINTENENCE_PC_FLAG: `${MASTER_DATA}/maintenance-pc-flag`,
    MAINTENENCE_CMS_FLAG: `${MASTER_DATA}/maintenance-cms-flag`,
    SEARCH_ADDRESS: `${BFF}/change-contact/search-address`,
    PREMIUM_PAYMENT_GET_POLICIES: `${PREMIUM_PAYMENT}/policies`,
    PREMIUM_PAYMENT_GET_POLICIES_CERT_NO: `${PREMIUM_PAYMENT}/policy/:certNo`,
    SUBMIT_PAYMENT_CEHCKOUT: `${PREMIUM_PAYMENT}/policy/:certNo/checkout`,
    MASTER_DATA_GET_COST_TYPE: `${MASTER_DATA}/cost-type`,
    MASTER_DATA_GET_RELATIONSHIP: `${MASTER_DATA}/relationship`,
    MASTER_DATA_GET_BANK: `${MASTER_DATA}/banks`,
    GET_PAYMENT_STATUS: `${PREMIUM_PAYMENT}/:orderRef/payment-status`,
    LOAN_SUBMIT: `${LOAN}/submission`,
    LOAN_POLICY_LIST_PAGING: `${LOAN}/policyLists`,
    LOAN_CANCEL: `${LOAN}/:policyNo/cancel`,
    LOAN_ROLLBACK: `${LOAN}/:policyNo/rollback`,
    SEARCH_AGENT_LIST: `${SEARCH_PARTNER}/agent`,
    BROKER_LIST: `${SEARCH_PARTNER}/broker`,
    TAX_CONSENT_POLICIES: `${TAX_CONSENT}/policies`,
    TAX_CONSENT_UPDATE_CONSENT: `${TAX_CONSENT}/submission`,
    USER_CONSENT: `${USER}/consents`,
    USER_SETTING_PREMIUM_REMINDER: `${USER}/settings/premium-reminder`,
};
