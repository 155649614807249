import React, { useEffect } from 'react';
import liff from '@line/liff';
import { useTranslation } from 'react-i18next';

import { trackPageView, trackEvent } from '../../utils/analytics';

function ConsentComplete() {
    const { t } = useTranslation();

    useEffect(async () => {
        trackPageView();
        trackEvent('Consent Submit', 'Consent Submit Success');
    }, []);

    return (
        <div className="payment-setting-page payment-credit">
            <div className="process-verified text-center">
                <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                >
                    <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                    />
                    <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                </svg>

                <h1 className="text-success">{t('consent_updated')}</h1>
                {/* <p>xxxxxxxx</p> */}

                <br />

                <a
                    className="btn-under"
                    onClick={() => {
                        trackEvent('Consent Complete', 'กลับไปที่เมนูหลัก');

                        liff.closeWindow();
                    }}
                >
                    {t('back_to_menu')}
                </a>
            </div>
        </div>
    );
}

export default ConsentComplete;
