import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // lng: 'th',
        fallbackLng: 'th',
        debug: true,
        cleanCode: true,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            lookupLocalStorage: 'i18nextLng',
            order: ['localStorage'],
            caches: ['localStorage'],
        },
        react: {
            useSuspense: false,
        },
        backend: {
            queryStringParams: { v: '1.0.1' },
        },
    });

export default i18n;
