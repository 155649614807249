import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const MainPageTaxConsent = Loadable({
    loader: () => import('./MainPageTaxConsent'),
    loading: () => null,
});

const Success = Loadable({
    loader: () => import('./Success'),
    loading: () => null,
});

const Footer = Loadable({
    loader: () => import('./components/Footer'),
    loading: () => null,
});

function TaxConsent(props) {
    return (
        <>
            <Switch>
                <Route exact path="/tax-consent" component={MainPageTaxConsent} />
                <Route path="/tax-consent/success" component={Success} />
            </Switch>
            <Footer />
        </>
    );
}

export default TaxConsent;
