import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import liff from '@line/liff';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { trackPageView, trackEvent } from '../../utils/analytics';

import OTPV2 from '../../components/otp';
import Popup from '../../components/popup';
import { consentState, userState } from '../../state';
import { updateUserConsents } from '../../actions';

// Running by Cypress, then use Stubbed LIFF SDK
if (window.Cypress) {
    window.liff = window.Cypress.liffMock;
} else {
    window.liff = liff;
}

const initialValues = {
    email: '',
    mobile_phone_orig: '',
    otp: '',
    ref: '',
};

function ConsentOTP(props) {
    const { t } = useTranslation();

    const [user] = useRecoilState(userState);
    const [updateConsent] = useRecoilState(consentState);

    const [otpType, setOtpType] = useState('mobile');
    const [, setOtpError] = useState(false);
    const [isSubmitError, setIsSubmitError] = useState(false);

    useEffect(() => {
        trackPageView();

        document.getElementById('main').style.padding = '0';
        document.getElementById('body').style.backgroundColor = '#f7f7f7';

        if (!user?.username) {
            props.history.push(`/setting`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = values => {
        console.log('values:', values);
    };

    const onChangeType = otpType => {
        trackEvent('Consent OTP', 'Change OTP Type: ' + otpType);

        setOtpType(otpType);
    };

    const onOTPReset = () => {
        setOtpError(false);
    };

    const onOTPError = () => {
        trackEvent('Consent OTP', 'OTP Failed');

        setOtpError(true);
    };

    const onOTPSuccess = data => {
        trackEvent('Consent OTP', 'OTP Passed', data);

        submitForm();
    };

    const submitForm = async () => {
        const response = await updateUserConsents(user.token, updateConsent)
        if (response.status === 'OK') {
            props.history.push(`/setting/consent-complete`);
        } else {
            trackEvent('Consent OTP', 'Submit update consent fail', { ...response });
            setIsSubmitError(true)
        }
    };

    if (!user.username) {
        return <></>;
    }

    const icons = otpType === 'mobile' ? 'smartphone' : 'mail';

    return (
        <div className="payment-setting-page payment-credit">
            <div className="text-center">
                <h1>{t('otp')}</h1>
                <img src={`/assets/icons/${icons}.svg`} alt={icons} />
                <br />
                <br />
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...initialValues,
                    email: user?.e_mail,
                    mobile_phone_orig: user?.otp_mobile_no,
                }}
                onSubmit={handleSubmit}
            >
                <Form>
                    <Field
                        name="otp"
                    >
                        {fieldProps => (
                            <OTPV2
                                {...fieldProps}
                                display="*"
                                maxLength={6}
                                delay={30}
                                verifyOptions={{
                                    max: 5,
                                    delay: 10,
                                }}
                                otpType={otpType}
                                onChangeType={onChangeType}
                                onReset={onOTPReset}
                                onError={onOTPError}
                                onSuccess={onOTPSuccess}
                            />
                        )}
                    </Field>
                </Form>
            </Formik>

            <Popup
                type="error"
                isOpen={isSubmitError}
                text={t('error_try_later')}
                btnText={t('back')}
                onClick={() => props.history.push(`/setting/consent`)}
            />

            <br />
            <br />
        </div>
    );
}

export default ConsentOTP;
