import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../../state';
import liff from '@line/liff';
import { trackUser, trackPageView, trackEvent } from '../../utils/analytics';
import { config } from '../../config';
import {
  getAccountMapping
} from '../../actions';
import { getParameterByName } from '../../utils/url';

const liffId = config.liff_id.groupEB;
const liffUrl = `https://liff.line.me/${liffId}`;

function GroupEB(props) {
  const params = new URLSearchParams(props.location.search);
  const userId = params.get('id');
  const [user, setUser] = useRecoilState(userState);
  const liffState = decodeURIComponent(params.get('liff.state'));
  const source = liffState ? getParameterByName(liffState, 'source') : null;
  const [profile, setProfile] = useState(userId ? { userId } : {});

  useEffect(() => {
    const initialize = async () => {
      if (userId) {
        _getAccount(userId)
      } else {
        if (liff.isInClient()) {
          try {
            await liff.init({ liffId });
            const profile = await liff.getProfile();
            setProfile(profile);
            await _getAccount(profile.userId);
          } catch (error) {
            if (profile?.userId) {
              await _getAccount(profile.userId);
            }
          }
        } else {
          await _getAccount(profile.userId);
        }
      }
      window.location.href = config.groupEB;
      return null;
    };

    initialize();
  }, []);

  const _getAccount = async _userId => {
    if (!user?.username) {
      const resultUser = await getAccountMapping(_userId);
      if (resultUser?.data && resultUser?.status === 'OK') {
        trackUser(resultUser.data);
        setUser(resultUser.data);
      }
    }

    trackPageView(source);
    trackEvent('Group Policy', 'Group Policy')
  }

  return <div></div>
}

export default GroupEB;