import {
    REQUEST_POLICY, RECEIVE_POLICY,
    REQUEST_UPDATE, RECEIVE_UPDATE,
    REQUEST_USER, RECEIVE_USER,
    REQUEST_USER_CHECK, RECEIVE_USER_CHECK,
} from '../actions';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_POLICY:
            return { ...state, loadingPolicy: true };
        case RECEIVE_POLICY:
            return { ...state, policyDetail: action.json, loadingPolicy: false };
        case REQUEST_UPDATE:
            return { ...state, loadingUpdate: true };
        case RECEIVE_UPDATE:
            return { ...state, updateDetail: action.json, loadingUpdate: false };
        case REQUEST_USER:
            return { ...state, loadingUser: true };
        case RECEIVE_USER:
            return { ...state, userDetail: action.json, loadingUser: false };
        case REQUEST_USER_CHECK:
            return { ...state, loadingUserCheck: true };
        case RECEIVE_USER_CHECK:
            return { ...state, userCheck: action.json, loadingUserCheck: false };
        default:
            return state;
    }
};

export default reducer;