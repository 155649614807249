import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const PolicyListContact = Loadable({
    loader: () => import('./PolicyListContact'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});
const NotFoundChangeContact = Loadable({
    loader: () => import('./NotFoundChangeContact'),
    loading: () => null,
});

const ConfirmationDetail = Loadable({
    loader: () => import('./ConfirmationDetail'),
    loading: () => null,
});

const RejectScreen = Loadable({
    loader: () => import('./RejectScreen'),
    loading: () => null,
});

const ChangeContactForm = Loadable({
    loader: () => import('./ChangeContactForm'),
    loading: () => null,
});

const ChangeContactOtp = Loadable({
    loader: () => import('./ChangeContactOtp'),
    loading: () => null,
});

export default function ChangeContactRoute() {
    return (
        <Switch>
            <Route
                exact
                path={`/change-contact`}
                component={PolicyListContact}
            />
            <Route
                path={`/change-contact/notfound-change-contact`}
                component={NotFoundChangeContact}
            />
            <Route
                path={`/change-contact/reject-screen`}
                component={RejectScreen}
            />
            <Route
                path={`/change-contact/confirmation-detail`}
                component={ConfirmationDetail}
            />
            <Route
                path={`/change-contact/change-contact-form`}
                component={ChangeContactForm}
            />
            <Route
                path={`/change-contact/confirmation-otp`}
                component={ChangeContactOtp}
            />
            <Route component={NotFound} />
        </Switch>
    );
}
