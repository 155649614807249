import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const PaymentBenefit = Loadable({
    loader: () => import('./PaymentBenefit'),
    loading: () => null,
});

const PaymentBenefitForm = Loadable({
    loader: () => import('./PaymentBenefitForm'),
    loading: () => null,
});

const PaymentBenefitSummary = Loadable({
    loader: () => import('./PaymentBenefitSummary'),
    loading: () => null,
});

const PaymentBenefitOtp = Loadable({
    loader: () => import('./PaymentBenefitOtp'),
    loading: () => null,
});

const PaymentBenefitComplete = Loadable({
    loader: () => import('./PaymentBenefitComplete'),
    loading: () => null,
});

const NotFound = Loadable({
    loader: () => import('../NotFound'),
    loading: () => null,
});

export default function PaymentBenefitRoute() {
    return (
        <Switch>
            <Route exact path={`/payout-benefit`} component={PaymentBenefit} />
            <Route
                path={`/payout-benefit/form`}
                component={PaymentBenefitForm}
            />
            <Route
                path={`/payout-benefit/summary`}
                component={PaymentBenefitSummary}
            />
            <Route path={`/payout-benefit/otp`} component={PaymentBenefitOtp} />
            <Route
                path={`/payout-benefit/complete`}
                component={PaymentBenefitComplete}
            />
            <Route component={NotFound} />
        </Switch>
    );
}
