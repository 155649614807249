export const markText = (
    text,
    options = { display: 'x', hide: 50, type: 'end' },
) => {
    const countText = text?.length;
    const countTextHide = Math.ceil(countText * (options.hide / 100));
    const startTextHide = countText - countTextHide - 1;

    let newText = '';

    // eslint-disable-next-line
    for (const i in text) {
        if (options.type === 'end') {
            if (i > startTextHide && i) newText += options.display;
            else newText += text[i];
        } else {
            if (i > startTextHide && i) newText += text[i];
            else newText += options.display;
        }
    }

    return newText;
};

export const markEmail = (
    email,
    options = { display: 'x', hide: 40, domain: false },
) => {
    const splitEmail = email.split('@');
    const name = splitEmail[0];
    const splitDomain = splitEmail[1].split('.');
    const domain = splitDomain[0];
    splitDomain.shift();
    const tld = '.' + splitDomain.join('.');
    const countName = name.length;
    const countDomain = domain.length;
    const countNameHide = Math.floor(countName * (options.hide / 100));
    const countDomainHide = Math.floor(countDomain * (options.hide / 100)) + 1;
    const startNameHide = countName - countNameHide - 1;

    let newName = '';
    let newDomain = '';

    // eslint-disable-next-line
    for (const i in name) {
        if (i > startNameHide && i < name.length - 2)
            newName += options.display;
        else newName += name[i];
    }

    // eslint-disable-next-line
    for (const n in domain) {
        if (n > 0 && n < countDomainHide) newDomain += options.display;
        else newDomain += domain[n];
    }

    newDomain = options.domain ? newDomain + tld : domain + tld;

    if (options.display === 'x') {
        newName = newName.replace(/x/g, '<span>x</span>');
    }

    return newName + '@' + newDomain;
};

export const markPhone = phone => {
    const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
    let newPhone = '';
    if (match) {
        newPhone = match[1] + '-' + match[2] + '-' + match[3];
    }

    function replaceAt(str, index, replacement) {
        return (
            str.substr(0, index) +
            replacement +
            str.substr(index + replacement.length)
        );
    }

    newPhone = replaceAt(newPhone, 2, 'X');
    newPhone = replaceAt(newPhone, 4, 'X');
    newPhone = replaceAt(newPhone, 5, 'X');
    newPhone = replaceAt(newPhone, 6, 'X');
    newPhone = newPhone.replace(/\*/g, '<span>X</span>');

    return newPhone;
};
